
import { WebSocketController } from "@/control/socket";
import { UserMediaController } from "./user-media";
import { Request } from "@asanrom/request-browser";
import { Peer } from "peerjs";
import { fetchFromLocalStorageCache, saveIntoLocalStorage } from "@/utils/local-storage";
import { AppPreferences } from "./app-preferences";
import { ApiCalls } from "@/api/api-group-calls";
import { AppEvents } from "./app-events";
import { ExitPreventer } from "@/utils/exit-preventer";
import { fixWebmDuration } from "@fix-webm-duration/fix";

function $t(s: string): string {
    return s;
}

/**
 * Calls controller
 * 
 * Depends on WebSocketController
 * 
 * Handles calls, using websockets
 * and WebRTC
 * 
 * Events:
 *  - 'open' (id, stream, userInfo: {name, image}, muted) - Emits when you join the call
 *  - 'close' - Emits when you leave the call
 * 
 *  - 'join-code-change' (code) - Emits when call code changes (for private calls)
 * 
 *  - 'self-mute' (muted) - Emits when mute status changes for yourself
 *  - 'self-change' (video, audio, hand) - Emits when preferences are changed
 * 
 *  - 'user-join' (id, userInfo: {name, image}, muted, video, audio, flags, hand)
 *  - 'user-leave' (id)
 *  - 'user-change' (id, muted, video, audio, hand)
 *  - 'user-stream' (id, stream)
 *  - 'user-volume' (id, volume)
 * 
 *  - 'screen-share-start' (isSelf, uid, userInfo: {name, image})
 *  - 'screen-share-stream' (uid, stream)
 *  - 'screen-share-end' (uid)
 * 
 *  - 'error-msg' (title, message) - Emits to warn the user about an error
 * 
 *  - 'expand-change' (bool) - Emits when call expands or contracts
 */

export class CallsController {

    public static room = "";
    public static joinedCall = false;
    private static events: { [key: string]: Array<(...args: any[]) => void> } = {};

    public static rejoinTimeout = null;
    public static rejoinCode = "";
    public static closed = true;

    public static callUsers = {};
    public static pendingCalls = {};
    public static pendingScreenCalls = {};

    public static preferences = {
        audio: true,
        video: true,
    };

    public static peer = null;
    public static peerID = "";

    public static callId = "";

    public static audio = true;
    public static video = true;
    public static hand = false;
    public static audioMode = false;

    public static selfUserStream = null;
    public static selfUserStreamAudio = null;
    public static selfId = "";

    public static screenPeer = null;
    public static screenPeerId = "";

    public static screenShareData = {};

    public static sharingScreen = false;
    public static sharingScreenSelf = false;
    public static screenShareStream = null;

    public static screenStream = null;
    public static mediaRecorder = null;
    public static expandedMode = false; //TODO: !!window.FORCED_CALL_EXPAND;

    public static init() {
        this.loadPreferences();

        AppEvents.AddEventListener('disconnect', this.onSocketDisconnected.bind(this));
        AppEvents.AddEventListener('room', this.onRoomChanged.bind(this));
        AppEvents.AddEventListener('room-leave', this.onRoomChanged.bind(this));

        AppEvents.AddEventListener('call-open', this.onCallOpen.bind(this));
        AppEvents.AddEventListener('call-close', this.onCallClose.bind(this));

        AppEvents.AddEventListener('call-user-join', this.onCallUserJoin.bind(this));
        AppEvents.AddEventListener('call-user-leave', this.onCallUserLeave.bind(this));

        AppEvents.AddEventListener('call-user-opt', this.onCallUserOptions.bind(this));

        AppEvents.AddEventListener('call-user-disc', this.onCallUserDisc.bind(this));

        AppEvents.AddEventListener('call-full-warning', this.onCallFullWarning.bind(this));

        AppEvents.AddEventListener('call-hand-turn', this.onCallHandTurn.bind(this));

        AppEvents.AddEventListener('call-mute', this.onCallMute.bind(this));

        AppEvents.AddEventListener('call-share-screen-start', this.onShareScreenStart.bind(this));
        AppEvents.AddEventListener('call-share-screen-end', this.onShareScreenEnd.bind(this));
        AppEvents.AddEventListener('call-share-screen-error', this.onShareScreenError.bind(this));

        ExitPreventer.addPrevent("call", ['exit-room', 'exit-page'], this.onExitRoom.bind(this));
        this.mediaRecorder = new MediaRecorder(CallsController.destination.stream);
        if (this.expandedMode) {
            document.body.classList.add("call-expanded-mode");
        } else {
            document.body.classList.remove("call-expanded-mode");
        }
    }

    public static isSupported(): boolean {
        if (!UserMediaController.isUserMediaSupported()) {
            return false;
        }

        return true;
    }

    /* Events */

    public static addEventListener(event: string, listener: (...args: any[]) => void) {
        if (!CallsController.events[event]) {
            CallsController.events[event] = [];
        }
        CallsController.events[event].push(listener);
    }

    public static on(event: string, listener: (...args: any[]) => void) {
        CallsController.addEventListener(event, listener);
    }

    public static removeEventListener(event: string, listener: (...args: any[]) => void) {
        if (!CallsController.events[event]) {
            return;
        }
        const i = CallsController.events[event].indexOf(listener);
        if (i >= 0) {
            CallsController.events[event].splice(i, 1);
            if (CallsController.events[event].length === 0) {
                delete CallsController.events[event];
            }
        }
    }

    public static emit(event: string, ...args: any[]) {
        if (!CallsController.events[event]) {
            return;
        }
        for (const listener of CallsController.events[event]) {
            listener(...args);
        }
    }

    /* Utils */

    public static createRandomCode() {
        const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let code = "";
        for (let i = 0; i < 9; i++) {
            code += chars[Math.floor(Math.random() * chars.length)];
        }
        return code;
    }

    public static findUserByPeer(pid: string) {
        for (const uid in this.callUsers) {
            if (this.callUsers[uid].peerId === pid) {
                return uid;
            }
        }
        return null;
    }

    public static findUserByScreenPeer(pid: string) {
        for (const uid in this.callUsers) {
            if (this.callUsers[uid].screenPeer === pid) {
                return uid;
            }
        }
        return null;
    }

    /* Preferences */

    public static loadPreferences() {
        try {
            this.preferences = fetchFromLocalStorageCache("call-preferences", { audio: true, video: true, });
        } catch (ex) {
            console.error(ex);
            this.preferences = {
                audio: true,
                video: true,
            };
        }

        this.audio = this.preferences.audio !== false;
        this.video = this.preferences.video !== false;
    }

    public static savePreferences() {
        try {
            saveIntoLocalStorage("call-preferences", this.preferences);
        } catch (ex) {
            console.error(ex);
        }
    }

    public static enableAudio(enabled: boolean) {
        this.audio = enabled;
        this.preferences.audio = enabled;

        this.savePreferences();

        if (this.joinedCall) {
            UserMediaController.setMediaStreamAudioEnabled(this.selfUserStream, enabled);
            WebSocketController.send({
                "type": "call-opt",
                "video": this.video,
                "audio": this.audio,
                "hand": this.hand,
            });
        }

        this.emit("self-change", this.video, this.audio, false);
    }

    public static enableVideo(enabled: boolean) {
        if (this.joinedCall) {
            if (!enabled && this.video) {
                this.video = enabled;
                this.preferences.video = enabled;
                this.savePreferences();

                UserMediaController.stopVideo(this.selfUserStream);

                WebSocketController.send({
                    "type": "call-opt",
                    "video": this.video,
                    "audio": this.audio,
                    "hand": this.hand,
                });

                this.emit("self-change", this.video, this.audio, this.hand);
            } else if (enabled && !this.video) {
                // Re-ask video stream
                this.video = enabled;
                this.preferences.video = enabled;

                this.savePreferences();
                this.emit("self-change", this.video, this.audio, this.hand);

                UserMediaController.getUserMediaOnlyVideo((stream) => {
                    if (!this.joinedCall || !this.video) {
                        // We do not need the stream anymore
                        UserMediaController.stopStream(stream);
                        return;
                    }
                    UserMediaController.injectVideo(this.selfUserStream, stream);
                    WebSocketController.send({
                        "type": "call-opt",
                        "video": this.video,
                        "audio": this.audio,
                        "hand": this.hand,
                    });
                    // Disconnect all the calls, since the stream changed
                    for (const uid of Object.keys(this.callUsers)) {
                        this.callUsers[uid].call && this.callUsers[uid].call.close();
                    }
                });
            }
        } else {
            this.video = enabled;
            this.preferences.video = enabled;
            this.savePreferences();
            this.emit("self-change", this.video, this.audio, this.hand);
        }
    }

    public static raiseHand(raised) {
        this.hand = raised;
        if (this.joinedCall) {
            WebSocketController.send({
                "type": "call-opt",
                "video": this.video,
                "audio": this.audio,
                "hand": this.hand,
            });
        }

        this.emit("self-change", this.video, this.audio, this.hand);
    }

    /* Events */

    public static onExitRoom(cont) {
        if (!cont) {
            return this.joinedCall;
        }
        if (this.joinedCall) {
            AppEvents.Emit("ask-leave-room", cont);
        } else {
            cont();
        }
    }

    /* Websocket event handlers */

    public static onSocketDisconnected() {
        if (this.joinedCall) {
            this.emit("error-msg", $t("Error"), $t("You left the call due to a connection error."));
            this.emit("close");
        }
        this.destroyCallData();
        this.rejoinCall();
    }

    public static onRoomChanged(msg) {
        if (msg && msg.room === this.room) {
            return; // Same room
        }
        this.room = msg ? msg.room : "";
        if (this.joinedCall) {
            this.emit("close");
        }
        this.destroyCallData();
    }

    public static onCallOpen(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        this.selfId = event.user_id;
        this.hand = false;
        this.callId = event.call_id || "";
        this.emit("open", event.user_id, this.audioMode ? this.selfUserStreamAudio : this.selfUserStream, { name: event.user_name, image: event.user_image }, event.muted, this.audioMode);
        this.emit("join-code-change", event.call_code || "");
        console.log("EMPIEZA LA LLAMADA")

    }

    public static onCallClose(event) {
        if (!this.joinedCall) return;
        this.hand = false;
        this.callId = "";
        let shouldRejoin = false;
        switch (event.reason) {
            case "BANNED":
                this.emit("error-msg", ("Error"), ("You are banned and cannot join the call."));
                break;
            case "ALREADY_IN_CALL":
                shouldRejoin = true;
                break;
            case "FULL":
                this.emit("error-msg", ("Error"), ("The call is full and it does not accept more users in it."));
                break;
            case "TIME_LIMIT":
                this.emit("error-msg", ("Error"), ("This room reached the time limit for the month. On the first day next month, the time usage will be reset."));
                break;
            case "PRIVATE_CALLS_LIMIT":
                this.emit("error-msg", ("Error"), ("This room reached the limit on the amount of private calls it can have."));
                break;
            case "CALL_CLOSED":
                this.emit("error-msg", ("Call closed"), ("The call was closed."));
                break;
            case "KICK":
                this.emit("error-msg", ("Call closed"), ("You were kicked from the call."));
                break;
        }
        this.emit("close");
        this.emit("join-code-change", "");
        if (shouldRejoin) {
            this.destroyCallData();
            this.rejoinCall();
        } else {
            this.closed = true;
            this.destroyCallData();
        }

        console.log("Call closed: " + event.reason);

        this.mediaRecorder.stop();
    }

    public static onCallUserJoin(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        const uid = event.user_id;
        if (this.selfId === uid) return;
        if (this.callUsers[uid]) return; // Already joined // Should not happen
        this.callUsers[uid] = {
            id: uid,
            stream: null,
            call: null,
            peerId: event.peer_id,
            muted: !!event.muted,
            audio: !!event.audio,
            video: !!event.video,
            hand: !!event.hand,
            audio_mode: !!event.audio_mode,
            userInfo: {
                name: event.user_name,
                image: event.user_image,
            },
            flags: event.flags,
            serializations: event.serializations,
        };

        // Event
        this.emit("user-join", uid, this.callUsers[uid].userInfo, this.callUsers[uid].muted, this.callUsers[uid].video, this.callUsers[uid].audio, this.callUsers[uid].flags, this.callUsers[uid].hand, this.callUsers[uid].audio_mode);

        // Call
        if (this.peerID < this.callUsers[uid].peerId) {
            // We must call the other peer, the other peer must answer
            this.callPeer(uid, this.callUsers[uid].peerId);
        } else if (this.pendingCalls[this.callUsers[uid].peerId]) {
            this.onCall(this.pendingCalls[this.callUsers[uid].peerId]);
            this.pendingCalls[this.callUsers[uid].peerId] = null;
        }

        // Screen share
        if (this.screenPeer && this.screenStream) {
            this.callPeerScreen(uid, this.callUsers[uid].peerId);
        }
    }

    public static onCallUserLeave(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;

        const uid = event.user_id;

        if (!this.callUsers[uid]) return;

        if (this.callUsers[uid].call) {
            this.callUsers[uid].call.removeAllListeners();
            this.callUsers[uid].call.close();
        }

        delete this.callUsers[uid];

        this.emit("user-leave", uid);
    }

    public static onCallMute(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        if (event.user_id !== this.selfId && this.callUsers[event.user_id]) {
            this.callUsers[event.user_id].muted = event.muted;
            UserMediaController.setMediaStreamAudioEnabled(this.callUsers[event.user_id].stream, !this.callUsers[event.user_id].muted);
            this.emit("user-change", event.user_id, this.callUsers[event.user_id].muted, this.callUsers[event.user_id].video, this.callUsers[event.user_id].audio, this.callUsers[event.user_id].hand);
        } else if (event.user_id === this.selfId) {
            this.emit("self-mute", event.muted);
        }
    }

    public static onShareScreenStart(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        const uid = event.user_id;

        if (!this.screenShareData[uid]) {
            this.screenShareData[uid] = {
                uid: event.user_id,
                name: event.user_name,
                image: event.user_image,
                peerId: "",
                stream: null,
                call: null,
            };
        }

        this.screenShareData[uid].uid = event.user_id;
        this.screenShareData[uid].name = event.user_name;
        this.screenShareData[uid].image = event.user_image;
        this.screenShareData[uid].peerId = event.peer_id;

        if (this.selfId === uid) {
            this.emit('screen-share-start', true, uid, {
                name: event.user_name,
                image: event.user_image,
            });

            this.screenShareData[uid].stream = this.screenStream;

            UserMediaController.detectStreamEnding(this.screenStream, this.endShareScreen.bind(this));

            this.emit('screen-share-stream', uid, this.screenShareData[uid].stream);

            // Call every other peer
            for (const uid in this.callUsers) {
                this.callPeerScreen(uid, this.callUsers[uid].peerId);
            }
        } else {
            const ssPeer = event.peer_id;
            this.emit('screen-share-start', false, uid, {
                name: event.user_name,
                image: event.user_image,
            });

            if (this.pendingScreenCalls[ssPeer]) {
                this.onScreenShareCall(this.pendingScreenCalls[ssPeer]);
                this.pendingScreenCalls[ssPeer] = null;
            }
        }
    }

    public static onShareScreenEnd(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;

        const uid = event.user_id;

        if (this.screenShareData[uid]) {
            if (this.screenShareData[uid].call) {
                this.screenShareData[uid].call.removeAllListeners();
                this.screenShareData[uid].call.close();
                this.screenShareData[uid].call = null;
            }

            delete this.screenShareData[uid];
            this.emit('screen-share-end', uid);
        }

        if (this.selfId === uid) {
            // The screen share was forced to end
            if (this.screenPeer) {
                this.screenPeer.destroy();
                this.screenPeer = null;
            }

            this.screenPeerId = "";

            if (this.screenStream) {
                UserMediaController.stopStream(this.screenStream);
                this.screenStream = null;
            }
        }
    }

    public static onCallUserOptions(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        if (event.user_id !== this.selfId && this.callUsers[event.user_id]) {
            this.callUsers[event.user_id].video = event.video;
            this.callUsers[event.user_id].audio = event.audio;
            this.callUsers[event.user_id].hand = event.hand;
            this.emit("user-change", event.user_id, this.callUsers[event.user_id].muted, this.callUsers[event.user_id].video, this.callUsers[event.user_id].audio, this.callUsers[event.user_id].hand);
        }
    }

    public static onCallUserDisc(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        const pid = event.peer_id + "";
        const uid = event.user_id + "";

        if (pid === this.peerID) {
            if (this.callUsers[uid] && this.callUsers[uid].call) {
                try {
                    this.callUsers[uid].call.close();
                } catch (ex) {
                    console.error(ex);
                }
            }
        } else if (pid === this.screenPeerId) {
            if (this.callUsers[uid] && this.callUsers[uid].screenCall) {
                try {
                    this.callUsers[uid].screenCall.close();
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
    }

    public static onCallFullWarning(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        this.emit("call-full-warning", event.user_name || "");
    }

    public static onCallHandTurn(event) {
        if (!this.joinedCall || event.room !== WebSocketController.room) return;
        if (event.user_id !== this.selfId && this.callUsers[event.user_id]) {
            this.callUsers[event.user_id].hand = false;
            this.emit("user-change", event.user_id, this.callUsers[event.user_id].muted, this.callUsers[event.user_id].video, this.callUsers[event.user_id].audio, this.callUsers[event.user_id].hand);
        } else {
            this.hand = false;
            this.emit("self-change", this.video, this.audio, this.hand);
        }

        this.emit("hand-turn", event.user_id);
    }

    public static onShareScreenError(event) {
        switch (event.reason) {
            case "ALREADY":
                this.emit("error-msg", ("Error"), ("There is already another user sharing screen."));
                break;
            default:
                this.emit("error-msg", ("Error"), ("Cannot share screen in this room."));
        }

        if (this.screenPeer) {
            this.screenPeer.destroy();
            this.screenPeer = null;
        }

        this.screenPeerId = "";

        if (this.screenStream) {
            UserMediaController.stopStream(this.screenStream);
            this.screenStream = null;
        }
    }

    public static onCall(call) {
        console.log("Received call from peer " + call.peer + " / Metadata: " + call.metadata);

        if (call.metadata === "screen") {
            return this.onScreenShareCall(call);
        }
        const pid = call.peer;
        if (this.peerID <= pid) return; // My pid must be greater for me to accept
        const uid = this.findUserByPeer(pid);
        if (!uid) {
            this.pendingCalls[pid] = call;
            return; // Mark as pending for now
        }

        console.log("Call from USER:  " + uid);

        // Accept call

        if (this.callUsers[uid].call) {
            try {
                this.callUsers[uid].call.removeAllListeners();
                this.callUsers[uid].call.close();
            } catch (ex) {
                console.error(ex);
            }
        }

        call.answer((this.audioMode || this.callUsers[uid].audio_mode) ? this.selfUserStreamAudio : this.selfUserStream);

        this.callUsers[uid].call = call;
        this.callUsers[uid].call.on("stream", (stream) => {
            console.log("Stream event fired (onCall)");
            if (this.callUsers[uid]) {
                this.callUsers[uid].stream = stream;
                this.emit("user-stream", uid, stream);
            }
        });

        let callClosed = false;

        this.callUsers[uid].call.on("error", (err) => {
            console.error(err);

            if (callClosed) {
                return;
            }
            callClosed = true;

            WebSocketController.send({
                type: "call-disc",
                peer: pid,
            });
        });

        this.callUsers[uid].call.on("close", () => {
            if (callClosed) {
                return;
            }
            callClosed = true;

            WebSocketController.send({
                type: "call-disc",
                peer: pid,
            });
        });
    }

    public static findScreenShareUID(peerID) {
        for (const u in this.screenShareData) {
            if (this.screenShareData[u].peerId === peerID) {
                return u;
            }
        }
        return null;
    }

    public static onScreenShareCall(call) {
        const peerId = call.peer;
        console.log("[SCREEN SHARE] Received call from peer: " + call.peer);
        const uid = this.findScreenShareUID(call.peer);
        console.log("[SCREEN SHARE] Call from USER:  " + uid);
        if (!uid) {
            this.pendingScreenCalls[call.peer] = call;
            return;
        }

        // Accept call
        call.answer(null); // Unidirectional

        if (this.screenShareData[uid].call) {
            try {
                this.screenShareData[uid].call.removeAllListeners();
                this.screenShareData[uid].call.close();
            } catch (ex) {
                console.error(ex);
            }
        }

        let callClosed = false;

        this.screenShareData[uid].call = call;
        this.screenShareData[uid].call.on("stream", (stream) => {
            this.screenShareData[uid].stream = stream;
            this.emit("screen-share-stream", uid, stream);
        });
        this.screenShareData[uid].call.on("error", (err) => {
            console.error(err);

            if (callClosed) {
                return;
            }
            callClosed = true;

            WebSocketController.send({
                type: "call-disc",
                peer: peerId,
            });
        });

        this.screenShareData[uid].call.on("close", () => {
            if (callClosed) {
                return;
            }
            callClosed = true;

            WebSocketController.send({
                type: "call-disc",
                peer: peerId,
            });
        });
    }

    /* Actions */

    public static destroyCallData() {
        this.joinedCall = false;

        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
            this.rejoinTimeout = null;
        }

        // Close calls

        for (const u in this.callUsers) {
            if (this.callUsers[u].call) {
                try {
                    this.callUsers[u].call.removeAllListeners();
                    this.callUsers[u].call.close();
                } catch (ex) {
                    console.error(ex);
                }
            }
        }

        for (const u in this.screenShareData) {
            if (this.screenShareData[u].call) {
                try {
                    this.screenShareData[u].call.removeAllListeners();
                    this.screenShareData[u].call.close();
                    this.screenShareData[u].call = null;
                } catch (e) {
                    console.error(e);
                }
            }
        }

        // Destroy peers

        if (this.peer) {
            this.peer.destroy();
            this.peer = null;
        }

        if (this.screenPeer) {
            this.screenPeer.destroy();
            this.screenPeer = null;
        }

        this.screenPeerId = "";

        this.screenShareData = {};
        this.callUsers = {};

        // Abort requests
        Request.Abort("call-join");
        Request.Abort("call-screen-share");

        this.selfId = "";

        if (this.selfUserStream) {
            UserMediaController.stopStream(this.selfUserStream);
            this.selfUserStream = null;
        }

        if (this.selfUserStreamAudio) {
            UserMediaController.stopStream(this.selfUserStreamAudio);
            this.selfUserStreamAudio = null;
        }

        if (this.screenStream) {
            UserMediaController.stopStream(this.screenStream);
            this.screenStream = null;
        }

        this.sharingScreen = false;
        this.sharingScreenSelf = false;
        this.screenShareStream = null;
        this.screenShareData = {};

        this.emit("join-code-change", "");

        this.emit("joining", false);
    }

    public static rejoinCall() {
        if (this.closed) {
            return;
        }
        this.emit("joining", true);
        if (this.rejoinTimeout) {
            clearTimeout(this.rejoinTimeout);
            this.rejoinTimeout = null;
        }
        this.rejoinTimeout = setTimeout(() => {
            this.rejoinTimeout = null;
            this.joinCall(this.rejoinCode, (err) => {
                if (err) {
                    //TODO:
                    //App.showMessage($t("Error"), err.message);
                }
            });
        }, 2000);
    }

    public static audioChunks = [];

    public static audioContext = new AudioContext();
    public static destination = CallsController.audioContext.createMediaStreamDestination();

    // public static joinCall(code, callback?) {
    //     if (typeof code === "function") {
    //         callback = code;
    //         code = "";
    //     }

    //     this.closed = false;

    //     this.rejoinCode = code;

    //     if (this.joinedCall) {
    //         this.leaveCall();
    //     }
    //     this.destroyCallData();

    //     this.emit("joining", true);

    //     if (!WebSocketController.room) {
    //         this.emit("joining", false);
    //         return callback(null);
    //     }

    //     this.audioMode = AppPreferences.AudioMode;

    //     Request.Pending("call-join", ApiCalls.GetRoomRoomidCall(WebSocketController.room, {}))
    //         .onSuccess((response) => {
    //             const peerConfig = {
    //                 host: response.configuration.peerjs.host,
    //                 port: response.configuration.peerjs.port,
    //                 secure: location.protocol === "https:",
    //                 key: response.configuration.peerjs.key,
    //                 config: response.configuration.rtc,
    //             };

    //             // Create new peer
    //             this.peer = new Peer(peerConfig);

    //             let socketOpen = false;

    //             this.peer.on("open", (id) => {
    //                 this.peerID = id;
    //                 socketOpen = true;

    //                 this.joinedCall = true;

    //                 UserMediaController.getUserMedia((stream) => {

    //                     CallsController.audioChunks = [];
    //                     const localAudioTracks = stream.getAudioTracks();
    //                     const localAudioStream = new MediaStream(localAudioTracks);

    //                     const localSource = CallsController.audioContext.createMediaStreamSource(localAudioStream);
    //                     localSource.connect(CallsController.destination);


    //                     this.mediaRecorder.ondataavailable = event => {
    //                         console.log("DATA AVAILABLE");
    //                         console.log("TAMAÑO: " + event.data.size);
    //                         if (event.data.size > 0) {
    //                             CallsController.audioChunks.push(event.data);
    //                         }
    //                     };

    //                     this.mediaRecorder.start();
    //                     const startTime = Date.now();
    //                     console.log("EMPIEZA A GRABAR");

    //                     this.mediaRecorder.onstop = () => {
    //                         console.log("TERMINA DE GRABAR");

    //                         if (CallsController.audioChunks.length > 0) {
    //                             let mimeType = '';

    //                             // Comprobación de formatos soportados
    //                             if (MediaRecorder.isTypeSupported('audio/mpeg')) {
    //                                 mimeType = 'audio/mpeg';
    //                                 console.log('MP3 es soportado. Usando audio/mpeg');
    //                             } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
    //                                 mimeType = 'audio/ogg';
    //                                 console.log('OGG es soportado. Usando audio/ogg');
    //                             } else if (MediaRecorder.isTypeSupported('audio/wav')) {
    //                                 mimeType = 'audio/wav';
    //                                 console.log('WAV es soportado. Usando audio/wav');
    //                             } else if (MediaRecorder.isTypeSupported('audio/webm')) {
    //                                 mimeType = 'audio/webm';
    //                                 console.log('WebM Opus es soportado. Usando audio/webm');
    //                             } else {
    //                                 console.error('Ningún formato de audio compatible encontrado.');
    //                                 return; // Salir si no hay formato soportado
    //                             }

    //                             // Crear el Blob con el formato soportado
    //                             const audioBlob = new Blob(CallsController.audioChunks, { type: mimeType });
    //                             const duration = Date.now() - startTime;

    //                             // Corregir la duración para formatos que lo necesiten
    //                             fixWebmDuration(audioBlob, duration).then((fixedBlob) => {
    //                                 const audioUrl = URL.createObjectURL(fixedBlob);
    //                                 const downloadLink = document.createElement('a');
    //                                 downloadLink.href = audioUrl;
    //                                 downloadLink.download = `grabacion.${mimeType.split('/')[1]}`; // Extensión basada en MIME type
    //                                 downloadLink.click();
    //                                 URL.revokeObjectURL(audioUrl);
    //                             });
    //                         } else {
    //                             console.error("No se grabaron fragmentos de audio. El archivo está vacío.");
    //                         }
    //                     };


    //                     if (!this.joinedCall) {
    //                         // Left the call in the middle of join
    //                         this.emit("joining", false);
    //                         return callback(new Error($t("Cannot join the call.")));
    //                     }

    //                     if (!stream) {
    //                         stream = UserMediaController.getPlaceholderStream();
    //                     }

    //                     // Get the stream
    //                     this.selfUserStream = stream;
    //                     this.selfUserStreamAudio = UserMediaController.removeVideoFromStream(stream);

    //                     // Apply options to stream
    //                     if (!this.video) {
    //                         UserMediaController.stopVideo(this.selfUserStream);
    //                     }
    //                     UserMediaController.setMediaStreamAudioEnabled(this.selfUserStream, this.audio);

    //                     UserMediaController.setMediaStreamAudioEnabled(this.selfUserStreamAudio, this.audio);

    //                     // Join the call
    //                     WebSocketController.send({
    //                         type: "call-join",
    //                         peer_id: id,
    //                         call_code: code,
    //                         video: this.video,
    //                         audio: this.audio,
    //                         audio_mode: this.audioMode,
    //                         serializations: ["binary", "binary-utf8", "json"]
    //                     });
    //                     this.emit("joining", false);
    //                     return callback(null, id);
    //                 });
    //             });

    //             this.peer.on('close', this.onSocketDisconnected.bind(this));
    //             this.peer.on('disconnected', this.onSocketDisconnected.bind(this));

    //             this.peer.on('call', this.onCall.bind(this));

    //             this.peer.on('error', (err) => {
    //                 if (!socketOpen) {
    //                     this.emit("joining", false);
    //                     this.rejoinCall();
    //                     return callback(null, "");
    //                 }
    //                 console.error(err);
    //             });
    //         })
    //         .onRequestError((err, handleErr) => {
    //             handleErr(err, {
    //                 unauthorized: () => {
    //                     return callback(new Error(("Unauthorized: Your session token expired. Please, refresh the page to log back in.")));
    //                 },
    //                 forbidden: () => {
    //                     return callback(new Error(("Access denied.")));
    //                 },
    //                 notFound: () => {
    //                     return callback(new Error(("The room you tried to join was not found.")));
    //                 },
    //                 temporalError: () => {
    //                     this.rejoinCall();
    //                     return callback(null, "");
    //                 },
    //             });
    //         })
    //         .onUnexpectedError(() => {
    //             this.rejoinCall();
    //             return callback(null, "");
    //         });
    // }

    public static joinCall(code, callback?) {
        if (typeof code === "function") {
            callback = code;
            code = "";
        }

        this.closed = false;
        this.rejoinCode = code;

        if (this.joinedCall) {
            this.leaveCall();
        }
        this.destroyCallData();

        this.emit("joining", true);

        if (!WebSocketController.room) {
            this.emit("joining", false);
            return callback(null);
        }

        this.audioMode = AppPreferences.AudioMode;

        Request.Pending("call-join", ApiCalls.GetRoomRoomidCall(WebSocketController.room, {}))
            .onSuccess((response) => {
                const peerConfig = {
                    host: response.configuration.peerjs.host,
                    port: response.configuration.peerjs.port,
                    secure: location.protocol === "https:",
                    key: response.configuration.peerjs.key,
                    config: response.configuration.rtc,
                };

                // Crear un nuevo peer
                this.peer = new Peer(peerConfig);

                let socketOpen = false;

                this.peer.on("open", (id) => {
                    this.peerID = id;
                    socketOpen = true;

                    this.joinedCall = true;

                    // Usar navigator.mediaDevices para obtener el stream
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then((stream) => {
                            CallsController.audioChunks = [];
                            const localAudioTracks = stream.getAudioTracks();
                            const localAudioStream = new MediaStream(localAudioTracks);
    
                            const localSource = CallsController.audioContext.createMediaStreamSource(localAudioStream);
                            localSource.connect(CallsController.destination);
    
                            this.mediaRecorder = new MediaRecorder(localAudioStream); // Crear el MediaRecorder aquí
    
                            this.mediaRecorder.ondataavailable = event => {
                                console.log("DATA AVAILABLE");
                                console.log("TAMAÑO: " + event.data.size);
                                if (event.data.size > 0) {
                                    CallsController.audioChunks.push(event.data);
                                }
                            };
    
                            this.mediaRecorder.start();
                            const startTime = Date.now();
                            console.log("EMPIEZA A GRABAR");
    
                            this.mediaRecorder.onstop = () => {
                                console.log("TERMINA DE GRABAR");
    
                                if (CallsController.audioChunks.length > 0) {
                                    let mimeType = '';
    
                                    // Comprobación de formatos soportados
                                    if (MediaRecorder.isTypeSupported('audio/wav')) {
                                        mimeType = 'audio/wav';
                                        console.log('MP3 es soportado. Usando audio/wav');
                                    } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
                                        mimeType = 'audio/ogg';
                                        console.log('OGG es soportado. Usando audio/ogg');
                                    } else if (MediaRecorder.isTypeSupported('audio/mpeg')) {
                                        mimeType = 'audio/mpeg';
                                        console.log('mpeg es soportado. Usando audio/mpeg');
                                    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
                                        mimeType = 'audio/webm';
                                        console.log('WebM Opus es soportado. Usando audio/webm');
                                    } else {
                                        console.error('Ningún formato de audio compatible encontrado.');
                                        return; // Salir si no hay formato soportado
                                    }
    
                                    // Crear el Blob con el formato soportado
                                    const audioBlob = new Blob(CallsController.audioChunks, { type: mimeType });
                                    const duration = Date.now() - startTime;
    
                                    // Corregir la duración para formatos que lo necesiten
                                    fixWebmDuration(audioBlob, duration).then((fixedBlob) => {
                                        
                                        const formData = new FormData();
                                        formData.append("audio", fixedBlob);
                                        Request.Pending("upload-audio", ApiCalls.PostRoomRoomidCallAudio(WebSocketController.room, {file: fixedBlob}))
                                        .onSuccess((response) => {
                                            console.log(response);
                                        });
                                    });
                                } else {
                                    console.error("No se grabaron fragmentos de audio. El archivo está vacío.");
                                }
                            };
    
                            if (!this.joinedCall) {
                                // Salió de la llamada en medio de la unión
                                this.emit("joining", false);
                                return callback(new Error($t("Cannot join the call.")));
                            }
    
                            // Obtener el stream
                            this.selfUserStream = stream;
                            this.selfUserStreamAudio = UserMediaController.removeVideoFromStream(stream);
    
                            // Aplicar opciones al stream
                            if (!this.video) {
                                UserMediaController.stopVideo(this.selfUserStream);
                            }
                            UserMediaController.setMediaStreamAudioEnabled(this.selfUserStream, this.audio);
                            UserMediaController.setMediaStreamAudioEnabled(this.selfUserStreamAudio, this.audio);
    
                            // Unirse a la llamada
                            WebSocketController.send({
                                type: "call-join",
                                peer_id: id,
                                call_code: code,
                                video: this.video,
                                audio: this.audio,
                                audio_mode: this.audioMode,
                                serializations: ["binary", "binary-utf8", "json"]
                            });
                            this.emit("joining", false);
                            return callback(null, id);
                        })
                        .catch((err) => {
                            console.error("Error al obtener acceso al micrófono:", err);
                            this.emit("joining", false);
                            return (new Error("No se pudo acceder al micrófono."));
                        });

                });

                this.peer.on('close', this.onSocketDisconnected.bind(this));
                this.peer.on('disconnected', this.onSocketDisconnected.bind(this));
                this.peer.on('call', this.onCall.bind(this));

                this.peer.on('error', (err) => {
                    if (!socketOpen) {
                        this.emit("joining", false);
                        this.rejoinCall();
                        return callback(null, "");
                    }
                    console.error(err);
                });
            })
            .onRequestError((err, handleErr) => {
                handleErr(err, {
                    unauthorized: () => {
                        return callback(new Error(("Unauthorized: Your session token expired. Please, refresh the page to log back in.")));
                    },
                    forbidden: () => {
                        return callback(new Error(("Access denied.")));
                    },
                    notFound: () => {
                        return callback(new Error(("The room you tried to join was not found.")));
                    },
                    temporalError: () => {
                        this.rejoinCall();
                        return callback(null, "");
                    },
                });
            })
            .onUnexpectedError(() => {
                this.rejoinCall();
                return callback(null, "");
            });
    }


    public static leaveCall() {
        this.closed = true;
        if (this.joinedCall) {
            WebSocketController.send({
                "type": "call-leave"
            });
        } else {
            this.destroyCallData();
        }
    }

    public static callPeer(uid: string, peerId: string) {
        console.log("Calling user: " + uid);
        const options = {
            'constraints': {
                'mandatory': {
                    'OfferToReceiveAudio': true,
                    'OfferToReceiveVideo': true
                },
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1,
            }
        };

        if (this.callUsers[uid].call) {
            try {
                this.callUsers[uid].call.removeAllListeners();
                this.callUsers[uid].call.close();
            } catch (ex) {
                console.error(ex);
            }
        }

        const streamForCall = (this.audioMode || this.callUsers[uid].audio_mode) ? this.selfUserStreamAudio : this.selfUserStream;
        const call = this.peer.call(peerId, streamForCall, options);
        this.callUsers[uid].call = call;
        this.callUsers[uid].call.on("stream", (stream) => {
            console.log("Stream event fired (callUser)");
            if (this.callUsers[uid]) {
                this.callUsers[uid].stream = stream;
                this.emit("user-stream", uid, stream);
            }
        });

        let callClosed = false;

        this.callUsers[uid].call.on("error", (err) => {
            console.error(err);
            console.log("Peer disconnected: " + peerId + " / User: " + uid);
            if (callClosed) {
                return;
            }
            callClosed = true;
            setTimeout(() => {
                if (this.callUsers[uid] && this.callUsers[uid].call === call && this.callUsers[uid].peerId === peerId) {
                    this.callPeer(uid, peerId);
                }
            }, 1000);
        });

        this.callUsers[uid].call.on("close", () => {
            console.log("Peer disconnected: " + peerId + " / User: " + uid);
            if (callClosed) {
                return;
            }
            callClosed = true;
            setTimeout(() => {
                if (this.callUsers[uid] && this.callUsers[uid].call === call && this.callUsers[uid].peerId === peerId) {
                    this.callPeer(uid, peerId);
                }
            }, 1000);
        });
    }

    public static callPeerScreen(uid, peerId) {
        console.log("[SCREEN SHARE] Calling " + uid);
        const call = this.screenPeer.call(peerId, this.screenStream, { metadata: "screen" });

        if (this.callUsers[uid].screenCall) {
            try {
                this.callUsers[uid].screenCall.removeAllListeners();
                this.callUsers[uid].screenCall.close();
            } catch (ex) {
                console.error(ex);
            }
        }

        this.callUsers[uid].screenCall = call;

        let callClosed = false;

        this.callUsers[uid].screenCall.on("error", (err) => {
            console.error(err);
            console.log("[Screen Share] Peer disconnected: " + peerId + " / User: " + uid);

            if (callClosed) {
                return;
            }
            callClosed = true;

            setTimeout(() => {
                if (this.screenPeer && this.screenStream && this.callUsers[uid] && this.callUsers[uid].screenCall === call && this.callUsers[uid].peerId === peerId) {
                    this.callPeerScreen(uid, peerId);
                }
            }, 1000);
        });

        this.callUsers[uid].screenCall.on("close", () => {
            console.log("[Screen Share] Peer disconnected: " + peerId + " / User: " + uid);

            if (callClosed) {
                return;
            }
            callClosed = true;

            setTimeout(() => {
                if (this.screenPeer && this.screenStream && this.callUsers[uid] && this.callUsers[uid].screenCall === call && this.callUsers[uid].peerId === peerId) {
                    this.callPeerScreen(uid, peerId);
                }
            }, 1000);
        });
    }

    public static startShareScreen(callback) {
        if (this.screenPeer) {
            return;
        }

        Request.Pending("call-screen-share", ApiCalls.GetRoomRoomidCall(WebSocketController.room, {}))
            .onSuccess((response) => {
                const peerConfig = {
                    host: response.configuration.peerjs.host,
                    port: response.configuration.peerjs.port,
                    secure: location.protocol === "https",
                    key: response.configuration.peerjs.key,
                    config: response.configuration.rtc,
                };

                // Create new peer
                this.screenPeer = new Peer(peerConfig);

                let socketOpen = false;

                this.screenPeer.on("open", (id) => {
                    this.screenPeerId = id;
                    socketOpen = true;

                    UserMediaController.getDisplayMedia((stream, ex) => {
                        if (!this.joinedCall) {
                            // Left the call in the middle of join
                            if (this.screenPeer) {
                                this.screenPeer.destroy();
                                this.screenPeer = null;
                            }
                            return callback(new Error(("Left the call while sharing screen.")));
                        }

                        if (!stream) {
                            if (this.screenPeer) {
                                this.screenPeer.destroy();
                                this.screenPeer = null;
                            }
                            if (ex && (ex + "").toLowerCase().indexOf("by system") >= 0) {
                                //TODO:
                                //App.$refs.shareScreenErrorModal.show();
                            }
                            return callback(null);
                        }

                        // Get the stream and join the call
                        this.screenStream = stream;
                        WebSocketController.send({
                            type: "call-share-screen-start",
                            peer_id: id,
                        });

                        return callback(null, id);
                    });
                });

                this.peer.on('error', (err) => {
                    if (!socketOpen) {
                        return callback(new Error(("Fatal error") + ": " + err.message));
                    }
                    console.error(err);
                });
            })
            .onRequestError((err, handleErr) => {
                handleErr(err, {
                    unauthorized: () => {
                        return callback(new Error(("Unauthorized: Your session token expired. Please, refresh the page to log back in.")));
                    },
                    forbidden: () => {
                        return callback(new Error(("Access denied.")));
                    },
                    notFound: () => {
                        return callback(new Error(("The room you tried to join was not found.")));
                    },
                    temporalError: () => {
                        return callback(new Error(("Cannot share screen due to a connection error.")));
                    },
                });
            })
            .onUnexpectedError((err) => {
                return callback(new Error(("Unexpected error. Error message: ") + err.message));
            });
    }

    public static endShareScreen() {
        if (!this.screenPeer) {
            return;
        }

        WebSocketController.send({
            type: "call-share-screen-end"
        });
    }

    public static changeExpandedMode(e) {
        this.expandedMode = e;
        if (this.expandedMode) {
            document.body.classList.add("call-expanded-mode");
        } else {
            document.body.classList.remove("call-expanded-mode");
        }
        this.emit("expand-change", this.expandedMode)
    }
}
